<template>
  <footer class="app-footer">
    <div class="group">
      <accordeon
        v-for="(menu, index) in accordeonItems"
        :key="index"
        :heading="menu?.title"
        :items="menu?.items"
        :isOpen="true"
        mode="toggle"
      >
        <template #header="{ heading }">
          <h4>{{ heading }}</h4>
        </template>

        <template #content="{ item }: { item: ILink }">
          <atomic-link :href="item.url" :targetBlank="item?.targetBlank">
            {{ item.label }}
          </atomic-link>
        </template>
      </accordeon>

      <partners
        v-if="layoutData?.footer?.partners?.isShow"
        :label="layoutData?.footer?.partners?.label || defaultLocaleLayoutData?.footer?.partners?.label"
        :items="layoutData?.footer?.partners?.items || defaultLocaleLayoutData?.footer?.partners?.items"
      />

      <list-base
        :items="layoutData?.footer?.responsibilityIcons || defaultLocaleLayoutData?.footer?.responsibilityIcons"
      >
        <template #header>
          <h4>{{ layoutData?.footer?.responsibilityLabel || defaultLocaleLayoutData?.footer?.responsibilityLabel }}</h4>
        </template>

        <template #item="{ item }">
          <atomic-image :src="`${item.image}`" />
        </template>
      </list-base>
    </div>

    <atomic-divider />

    <client-only>
      <list-paysis />
    </client-only>

    <atomic-divider />

    <template v-if="layoutData?.footer?.custom?.show || defaultLocaleLayoutData?.footer?.custom?.show">
      <div class="info">
        <div
          id="anj-bcc3f67c-d2df-479c-8025-bb7bc3e6987e"
          class="info__image-link"
          style="flex-shrink: 0"
          data-anj-seal-id="bcc3f67c-d2df479c-8025-bb7bc3e6987e"
          data-anj-image-size="80"
          data-anj-image-type="basic-small"
        />

        <div
          v-if="layoutData?.footer?.custom?.description || defaultLocaleLayoutData?.footer?.custom?.description"
          class="info__text"
          v-html="customLicenceDescription"
        />
      </div>

      <atomic-divider />
    </template>

    <div class="copy-info" style="text-align: center">
      <p>{{ layoutData?.footer?.copyright || defaultLocaleLayoutData?.footer?.copyright }}</p>
    </div>
  </footer>
</template>

<script setup lang="ts">
  import { marked } from 'marked';
  import type { ILink } from '~/types';
  import DOMPurify from 'isomorphic-dompurify';

  const { layoutData, defaultLocaleLayoutData } = useGlobalStore();
  const accordeonItems = [
    layoutData?.footer?.promoMenu || defaultLocaleLayoutData?.footer?.promoMenu,
    layoutData?.footer?.infoMenu || defaultLocaleLayoutData?.footer?.infoMenu,
    layoutData?.footer?.helpMenu || defaultLocaleLayoutData?.footer?.helpMenu,
  ].filter(menu => menu?.title && menu?.items?.length);

  const customLicenceDescription = computed(() => {
    const descriptionContent =
      layoutData?.footer?.custom?.description || defaultLocaleLayoutData?.footer?.custom?.description;
    if (!descriptionContent) return '';
    return DOMPurify.sanitize(marked.parse(descriptionContent) as string, { FORBID_TAGS: ['style'] });
  });

  onMounted(() => {
    if (layoutData?.footer?.custom?.show || defaultLocaleLayoutData?.footer?.custom?.show) {
      (window as any)?.anj_bcc3f67c_d2df_479c_8025_bb7bc3e6987e?.init();
    }
  });
</script>

<style src="@skeleton/assets/styles/components/layout/footer.scss" lang="scss" />
